<template>
  <div id="app">
    <main>
      <fade-transition origin="center" mode="out-in" :duration="250">
        <router-view :key="$route.fullPath" />
      </fade-transition>
    </main>
  </div>
</template>
<!-- eslint-disable -->
<script>
import { FadeTransition } from "vue2-transitions";

export default {
  data() {
    return {
      windowHeight: window.innerHeight,
    };
  },
  watch: {
    windowHeight(newHeight, oldHeight) {
      console.log(`it changed to ${newHeight} from ${oldHeight}`);
    },
  },
  created() {
    window.addEventListener("resize", this.getSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.getSize);
  },
  methods: {
    getSize() {
      console.log("////////////////////", document.height, screen.height);
      this.windowHeight = screen.height;
    },
    getStyle() {
      //return `height:${this.windowHeight}px`;
    },
  },
  components: {
    FadeTransition,
  },
};
</script>
