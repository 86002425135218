/* eslint-disable */
import axios from 'axios';
import dotenv from 'dotenv';
import jwt from 'jsonwebtoken';
import router from './../router';
dotenv.config();

var apiIP = '';
if (process.env.VUE_APP_IP_API) {
  apiIP = `${process.env.VUE_APP_IP_API}`;
  if (process.env.VUE_APP_PORT_REST)
    apiIP = apiIP + `:${process.env.VUE_APP_PORT_REST}`;
} else {
  apiIP = 'https://api2.bbonebrasil.com.br';
}

const dominio = window.location.href.toString();
const isStaging = dominio.indexOf('staging.') >= 0;
const isQa = dominio.indexOf('qa.') >= 0;

if (isQa) {
  apiIP = 'https://qa.bbonebrasil.com.br';
}

if (isStaging) {
  apiIP = 'https://staging.bbonebrasil.com.br';
}

const getIdEmpresa = () => {
  const decoded = jwt.decode(localStorage.getItem('user-token'));
  if (decoded) {
    return decoded.id_empresa;
  }
  return null;
};

export default {
  getJson(jsonName) {
    return new Promise((resolve, reject) => {
      try {
        var endpoint =
          apiIP + '/g/buscaJson/' + process.env.VUE_APP_SITE + '/' + jsonName;

        axios
          .get(endpoint)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            reject(error);
          });
      } catch (e) {
        reject(e);
      }
    });
  },
  getAll(controllerName, extraParams, action = 'list') {
    return new Promise((resolve, reject) => {
      try {
        var endpoint = `${apiIP}/${controllerName}/${action}`;
        if (extraParams)
          if (extraParams.PageNumber != undefined) {
            if (action == '') {
              endpoint = endpoint + 'Page/' + extraParams.PageNumber;
            } else {
              endpoint = endpoint + '/' + extraParams.PageNumber;
            }
          }
        endpoint = endpoint
          .replace('idEmpresa=null', getIdEmpresa())
          .replace('idEmpresa=undefined', getIdEmpresa());

        var token = localStorage.getItem('user-token');
        axios
          .get(endpoint, {
            headers: {
              Authorization: token,
            },
          })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status == 401) {
                let idEmpresa = localStorage.getItem('idEmpresa');
                alert('Sessão Expirada, efetue novo login');
                router.push(`/?idEmpresa=${idEmpresa}`);
                reject('Sessão Expirada, efetue novo login');
              }
              if (error.response.data.error) {
                reject(error.response.data.error.errorMessage);
              } else {
                reject('Ação não Encontrada');
              }
            } else {
              // antigo Erro Sistemico
              alert('Falha na conexão com internet, tente novamente');
              reject('Falha na conexão com internet, tente novamente');
            }
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  getOne(controllerName, extraParams) {
    return new Promise((resolve, reject) => {
      try {
        const endpoint = `${apiIP}/${controllerName}/${extraParams.PrimaryKey}/${extraParams.PageNumber}`;

        axios
          .get(endpoint, {
            extraParams,
          })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  getFilter(controllerName, extraParams) {
    return new Promise((resolve, reject) => {
      try {
        const endpoint = `${apiIP}/${controllerName}/filter/${extraParams.SearchText}?page=${extraParams.PageNumber}`;

        axios
          .get(endpoint, {
            extraParams,
          })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error);
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  post(controllerName, action = 'create', data, extraParams) {
    return new Promise((resolve, reject) => {
      try {
        var endpoint = `${apiIP}/${controllerName}/${action}`;

        if (extraParams)
          if (extraParams.PageNumber != undefined) {
            if (action == '') {
              endpoint = endpoint + 'Page/' + extraParams.PageNumber;
            } else {
              endpoint = endpoint + '/' + extraParams.PageNumber;
            }
          }
        if (getIdEmpresa() && !data.idEmpresa && !data.id_empresa)
          data.idEmpresa = getIdEmpresa();

        var token = localStorage.getItem('user-token');
        axios
          .post(endpoint, data, {
            headers: {
              Authorization: token,
            },
          })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status == 401) {
                let idEmpresa = localStorage.getItem('idEmpresa');
                alert('Sessão Expirada, efetue novo login');
                router.push(`/?idEmpresa=${idEmpresa}`);
                reject('Sessão Expirada, efetue novo login');
              }
              if (error.response.data.error) {
                reject(error.response.data.error.errorMessage);
              } else {
                reject('Ação não Encontrada');
              }
            } else {
              // antigo Erro Sistemico
              alert('Falha na conexão com internet, tente novamente');
              reject('Falha na conexão com internet, tente novamente');
            }
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  login(controllerName, action = 'login', data) {
    return new Promise((resolve, reject) => {
      try {
        var endpoint = `${apiIP}/${controllerName}/${action}`;
        var basicAuth = 'Basic ' + btoa(JSON.stringify(data));

        axios
          .post(
            endpoint,
            {},
            {
              headers: {
                Authorization: basicAuth,
              },
            },
          )
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            if (error.response.data) {
              if (error.response.data.error) {
                if (error.response.data.error.errorMessage)
                  reject(error.response.data.error.errorMessage);
                else reject();
              } else {
                reject(error);
              }
            }
            if (error) {
              reject();
            }
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  getEndPoint(controllerName, action, data, extraParams) {
    var endpoint = `${apiIP}/${controllerName}/${action}`;
    return endpoint;
  },

  upload(controllerName, action, data, extraParams) {
    return new Promise((resolve, reject) => {
      try {
        var endpoint = `${apiIP}/${controllerName}/${action}`;

        axios
          .post(endpoint, data, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*',
            },
          })
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            if (error.response.data) {
              if (error.response.data.error) {
                if (error.response.data.error.errorMessage)
                  reject(error.response.data.error.errorMessage);
                else reject();
              } else {
                reject(error);
              }
            }
            if (error) {
              reject();
            }
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  put(controllerName, action = 'update', data, extraParams) {
    return new Promise((resolve, reject) => {
      try {
        var endpoint = `${apiIP}/${controllerName}/${action}`;
        if (getIdEmpresa() && !data.idEmpresa && !data.id_empresa)
          data.idEmpresa = getIdEmpresa();
        var token = localStorage.getItem('user-token');

        axios
          .put(endpoint, data, {
            headers: {
              Authorization: token,
            },
          })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.status == 401) {
                let idEmpresa = localStorage.getItem('idEmpresa');
                alert('Sessão Expirada, efetue novo login');
                router.push(`/?idEmpresa=${idEmpresa}`);
                reject('Sessão Expirada, efetue novo login');
              }
              if (error.response.data.error) {
                reject(error.response.data.error.errorMessage);
              } else {
                reject('Ação não Encontrada');
              }
            } else {
              // antigo Erro Sistemico
              alert('Falha na conexão com internet, tente novamente');
              reject('Falha na conexão com internet, tente novamente');
            }
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  delete(controllerName, action = 'update', data, extraParams) {
    return new Promise((resolve, reject) => {
      try {
        var endpoint = `${apiIP}/${controllerName}/${action}`;

        data = {
          headers: {
            Authorization: token,
          },
        };
        axios
          .delete(endpoint, data)
          .then(function (response) {
            resolve(response);
          })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            reject(error.response.data.error.errorMessage);
          });
      } catch (e) {
        reject(e);
      }
    });
  },

  validaToken(token, controllerName, action) {
    return new Promise((resolve, reject) => {
      try {
        var endpoint = `${apiIP}/${controllerName}/${action}`;

        axios
          .post(
            endpoint,
            {
              front: true,
            },
            {
              headers: {
                Authorization: token,
              },
            },
          )
          .then(function (response) {
            resolve(response);
          })
          .catch(function (error) {
            resolve({
              data: {
                result: false,
              },
            });
          });
      } catch (e) {
        reject(e);
      }
    });
  },
};
