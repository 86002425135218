import Vue from "vue";
import App from "./App.vue";
import router from "./router";
//import './registerServiceWorker'

import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-swipe-actions/dist/vue-swipe-actions.css";
import VueCurrencyFilter from "vue-currency-filter";
import VueScrollTo from "vue-scrollto";
import VueMask from "v-mask";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import "@/assets/css/main.css";
import "@/assets/css/fonts.css";

import VeeValidate from "vee-validate";

Vue.use(VeeValidate, {
  // This is the default
  inject: true,
  // Important to name this something other than 'fields'
  fieldsBagName: "veeFields",
  // This is not required but avoids possible naming conflicts
  errorBagName: "veeErrors",
});

Vue.component("v-select", vSelect);

Vue.use(VueScrollTo);

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Vue.use(VueMask);

Vue.use(BootstrapVue);

Vue.use(VueCurrencyFilter, {
  symbol: "R$",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: true,
});

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTimes,
  faQuestionCircle,
  faChartLine,
  faWallet,
  faCar,
  faBars,
  faTruck,
  faMotorcycle,
  faPlus,
  faBell,
  faPhone,
  faEnvelope,
  faFileAlt,
  faCalendarAlt,
  faArrowLeft,
  faCheck,
  faCamera,
  faVideo,
  faChevronLeft,
  faFolder,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faTimes,
  faQuestionCircle,
  faChartLine,
  faWallet,
  faCar,
  faBars,
  faTruck,
  faMotorcycle,
  faPlus,
  faBell,
  faEdit,
  faPhone,
  faEnvelope,
  faFileAlt,
  faCalendarAlt,
  faArrowLeft,
  faCheck,
  faCamera,
  faVideo,
  faChevronLeft,
  faFolder
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

import VueApexCharts from "vue-apexcharts";

Vue.component("apexchart", VueApexCharts);

import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

import VueSignaturePad from "vue-signature-pad";

Vue.use(VueSignaturePad);

import VueGeolocation from "vue-browser-geolocation";
Vue.use(VueGeolocation);

import VueThreeSixty from "vue-360";

import "vue-360/dist/css/style.css";

Vue.use(VueThreeSixty);

import { Datetime } from "vue-datetime";
// You need a specific loader for CSS files
import "vue-datetime/dist/vue-datetime.css";

Vue.component("datetime", Datetime);

import VueKonva from "vue-konva";

Vue.use(VueKonva);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
